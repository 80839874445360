import { useEffect, useState } from "react";
import Topbar from "../components/header/topbar";
import { returnStatsData as returnData } from "../scripts/GetDataScripts";

const Lending = () => {
  const [MyArray, setMyArray] = useState([]);

  useEffect(async () => {
    // Update the document title using the browser API

    const data = await returnData();
    console.log("NO data")
    if (!data) {
      return <></>;
    }
    console.log("here is the new data")
    setMyArray(data)
    console.log(data, "this is the data");
    console.log(MyArray.percentage_positive_trades_w1);
   
  }, []);

  return (
    <div>
      <Topbar />

      
      <div class="max-w-sm mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 shadow-xl mt-10">
        <a href="#">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Hanhart Trading technology  
          </h5>
        </a>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          This month over {MyArray.amount_trades_m1} of trading signals are created with a winrate of {MyArray.percentage_positive_trades_w1}% and   
          an average yield of {MyArray.percentage_average_yield_m1}% untill so fare. <br></br> In our database, {MyArray.stocks_trending_long} 
          % of all stocks are trending long at this moment. 
          <br></br> <br></br>
          <p className="font-bold"> We building technology </p> For hedgefunds, pensionfunds and traders.
            That includes research-algoritmes for trend-discovery and moneyflows-aggerations, portfolio-selection and optimization algoritmes. 
            To offset risk, diversify and maximize oppertunity's. <br></br> <br></br> Our Proven strategy's are build with a unique vision, our portfolio's 
            are aways long-short, uncorrerlated-diversifyd, and carry between 5 and 500 unique equities. This results in intens high sharpratio's. 
            Because of these characteristics backtest of 40 years show that marketcycles have zero effect on the performance of this strategy wich makes this an investment 
            build for the future.

            <br></br> <br></br>
          <p className="font-bold"> We are the difference </p>
          <p>Our selection is based on equities that generated 14 signals in the last two years with a winrate of 100%, mixed toghetter in a unique mix.   

          </p>

               
              

        </p>
        
      </div>
    </div>
  );
};

export default Lending;
