import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Lending from "./pages/Lending";

//list your routes here
export const routes = [
    {path: "/test", element: <Home/>},
    {path: "*", element: <NotFound/>},
    {path: "/", element: <Lending/>},


]
