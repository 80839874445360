// fetches bodyparts with sub iD.
export async function returnStatsData() {
    
  var requestOptions = {
    method: 'GET',
    redirect: 'manual'
  };
  
  return fetch("https://www.trend-impact-service.nl/trend_strategy_status", requestOptions)
  .then(response => response.json())
}
